<template>
    <div v-if="isLoggedIn == true" class="variants_template">
      <div v-if="loader">
          <img id="loader" src="../../assets/Circles-menu-3.gif" />
      </div>
        <div v-if="variantsData.length"  class="product_inner">
            <table>
                <thead> 
                    <th>Variant ID</th>
                    <th>Product ID</th>
                    <th>Variant sku</th>
                    <th>Variant option1</th>
                    <th>Variant option2</th>
                    <th>Variant Quantity</th>
                    <th>Metafields</th>
                </thead>
                <tbody>
                    <tr v-for="(item) in variantsData" :key="item.id">
                       <td>{{ item.id }}</td> 
                       <td>{{ item.product_id }}</td> 
                       <td>{{ item.sku?item.sku:"null" }}</td> 
                       <td>{{ item.option1 }}</td> 
                       <td>{{ item.option2 }}</td> 
                       <td>{{ item.inventory_quantity }}</td> 
                       <td><a v-bind:href="`/products/variants/metafield/${ item.id }`">check date</a></td> 
                    </tr>
                </tbody>
            </table>
          </div>
          <div v-else>
              <span>
                    No Variant found with 1200 Quantity.
              </span> 
          </div>
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
  </template>
  
  <script>
  import { mapActions,mapGetters } from "vuex";
  export default {
    name: 'variants',
     components: {
     },
      data() {
        return {
          loader:true,
          variant_id:null,
          variantsData:[]
        }
     },
      methods: {
           ...mapActions(["variants"]),
           ...mapGetters(["isAuthenticated"]), 
          
      },
      mounted:async function () {
        if(this.isLoggedIn && this.$route.params.id){
            this.variantsData =  await this.variants({"id":this.$route.params.id,"token":this.userToken});
            this.loader = false;
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        userToken : function(){
          return this.$store.getters.getUserToken
        }
      },
  
  
  }
  </script>